@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --primary: #5b3b97;
  --primary-light: #6b4ea1;
  --primary-dark: #513587;
  --secondary: #7e74b6;
  --secondary-light: #8a81bd;
  --secondary-dark: #7168a3;
  --blue: #7ed3f0;
  --blue-light: #8ad7f1;
  --grey: #c2c2c2;
  --dark-grey: #333;
}

.App {
  text-align: center;
  background-color: var(--dark-grey);
  color: var(--grey);
  min-height: 100vh;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

p {
  font-size: 18px;
}

.bg-light {
  background-color: var(--grey);
  color: var(--dark-grey);
}

.bg-dark {
  background-color: var(--dark-grey) !important;
  color: var(--grey);
}

.parallax { 
  min-height: 500px;
  background: url('./images/purple-sky-moon-square.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

.text-purple {
  color: var(--secondary);
}

.text-light {
  color: var(--grey)
}

.text-dark {
  color: var(--dark-grey)
}

.text-purple:hover {
  color: var(--secondary-light)
}

.container {
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  min-width: 100%;
  max-width: 100%;
}

.video-div {
  font-size: 0;
}

.lotusVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-content {
  background-image: url('images/moonscape-meditation.png');
  background-size: cover;  
  background-repeat: no-repeat;
}

.profile-bg {
  background-size: cover;  
  background-repeat: no-repeat;
  background-position: center;
}

.profileImg {
  width: 100%;
  padding: 10px;
  margin: 10px;
}

.profileBorder {
  border-radius: 10px;
  border: 5px solid var(--blue);
  display: flex;
  max-width: fit-content;
  padding: 10px;
  margin: 10px;
}

.lotus-divider {
  min-width: 150px;
  max-width: 200px;
  width: 50%;
}

.background-tint {
  background-color: rgba(0,0,0, 0.6);
  background-blend-mode: multiply;
}

.background-tint-modal {
  background-color: rgba(0,0,0, 0.4);
  background-blend-mode: multiply;
}

.modal-text {
  font-weight: 600;
}

.modal-footer,
.modal-header {
  border: none;
}

.navbar {
  background-color: var(--dark-grey);
}

.nav-title {
  color: var(--grey)
}

.nav-title:hover {
  color: var(--grey)
}

.title {
  color: var(--secondary);
}

.btn {
  border-radius: 0 !important;
}

.btn-purple {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
  font-weight: 700;
}

.btn-purple:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--dark-grey);
}

.btn-media {
  color: var(--primary);
}

.btn-media:hover {
  color: var(--primary-light);
}

.btn-primary, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
  background-color: transparent;
  border-color: var(--blue);
  color: var(--blue);
  font-weight: 700;
}

.btn-primary:hover {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--dark-grey);
}

.btn-secondary, .btn-secondary:active, .btn-secondary:visited, .btn-secondary:focus {
  background-color: transparent;
  border-color: var(--secondary);
  color: var(--secondary);
  font-weight: 700;
}

.btn-secondary:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: var(--dark-grey);
}
.popup {
  min-height: 80px;
}

.popup > * {
  border-radius: 20px;
}

.copy {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.divider {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40px;
  width: 100%;  
}

.carousel-item {
  padding-bottom: 100px;
}

.testimonialImg {
  height: 200px;
}

.box1 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  height: 4px;
  width: 100%;
  background-image: -webkit-linear-gradient(
    left,
    var(--dark-grey),
    var(--primary),
    var(--dark-grey)
  );
}

.box2 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 4px;
  width: 100%;
  background-image: -webkit-linear-gradient(
    left,
    var(--dark-grey),
    var(--blue),
    var(--dark-grey)
  );
}

.box-gap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 8px;
  width: 100%;
  background-color: transparent;
}

.surveyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial {
  height: 100px;
}

.book-row {
  min-height: 80vh;
}

.footer {
  padding-top: 16px;
}

.footer, .divider {
  background-color: #333;
}

@media (max-width: 991px) {
  .testimonial {
    height: 300px;
  }

  .parallax { 
    height: 300px;
    background-attachment:scroll;  
  }
}